
import {
  IonContent, IonList, IonItem, IonPage, IonRefresher, IonRefresherContent, IonAvatar,
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({

  name: 'ListExhibitions',

  data() {
    return {
      exhibitions: [],
    };
  },

  setup() {
    const router = useRouter();
    return { router };
  },

  mounted() {
    this.getExhibitions();
  },

  methods: {
    // TODO refactor into data servide?
    getExhibitions() {
      return this.$http.get('/api/exhibitions').then((response) => {
        this.exhibitions = response.data.data;

        return response.data;
      });
    },

    refresh(ev: CustomEvent) {
      this.getExhibitions().then(() => {
        ev.detail.complete();
      });
    },
  },

  components: {
    IonContent,
    IonList,
    IonItem,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonAvatar,
  },
});
