import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import ListExhibitions from '../views/ListExhibitions.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/exhibitions'
  },
  {
    path: '/exhibitions',
    name: 'Exhibitions',
    component: ListExhibitions
  },
  {
    path: '/exhibitions/:slug/:items*',
    name: 'Exhibition',
    component: () => import('../views/ViewExhibition.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
